import React from "react"
import Section from "src/components/section"
import { useHomeStore } from "src/state/home"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { blueCircle, redCircle } from "src/components/svgs"
import { useOrderMenuStore } from "src/state/order-menu"

const HomeSection2 = () => {
  const {
    section2: {
      title,
      subtitle1,
      subtitle2,
      circle1Text,
      circle2Text,
      circle3Text,
      circle4Text,
      buttonText,
    },
  } = useHomeStore()
  const { setShouldShowOrderMenu } = useOrderMenuStore()

  return (
    <Section className="home-section-2">
      <div className="copy">
        <div className="left">
          <h2 className="title">
            <Markup>{title}</Markup>
          </h2>
          <p className="subtitle-1">
            <Markup>{subtitle1}</Markup>
          </p>
        </div>
        <div className="right">
          <h3 className="subtitle-2">
            <Markup>{subtitle2}</Markup>
          </h3>
        </div>
      </div>
      <div className="circles parallax-2">
        <div className="circle circle-1">
          <Markup>{redCircle}</Markup>
          <p className="circle-text">{circle1Text}</p>
        </div>
        <div className="circle circle-2">
          <Markup>{blueCircle}</Markup>
          <p className="circle-text">{circle2Text}</p>
        </div>
        <div className="circle circle-3">
          <Markup>{redCircle}</Markup>
          <p className="circle-text">{circle3Text}</p>
        </div>
        <div className="circle circle-4">
          <Markup>{blueCircle}</Markup>
          <p className="circle-text">{circle4Text}</p>
        </div>
      </div>
      <div className="order-btn">
        <PillButton onClick={() => setShouldShowOrderMenu(true)}>{buttonText}</PillButton>
      </div>
    </Section>
  )
}

export default HomeSection2
