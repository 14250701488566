import React from "react"
import { Link } from "gatsby"
import Section from "src/components/section"
import { useHomeStore } from "src/state/home"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { redCircle, quoteGraphic } from "src/components/svgs"

const HomeSection4 = () => {
  const {
    section4: { title, buttonText, quote1, quote2, quote3 },
  } = useHomeStore()

  return (
    <Section className="home-section-4">
      <h2 className="title parallax-1">{title}</h2>
      <div className="circles">
        <div className="circle circle-1">
          <div className="bg">
            <Markup>{redCircle}</Markup>
          </div>
          <div className="copy">
            <div className="quote-graphic">
              <Markup>{quoteGraphic}</Markup>
            </div>
            <p className="quote">
              <Markup>{quote1.quote}</Markup>
            </p>
            <div className="border" />
            <p className="source">
              <Markup>{quote1.source}</Markup>
            </p>
          </div>
        </div>
        <div className="circle circle-2">
          <div className="bg">
            <Markup>{redCircle}</Markup>
          </div>

          <div className="copy">
            <div className="quote-graphic">
              <Markup>{quoteGraphic}</Markup>
            </div>
            <p className="quote">
              <Markup>{quote2.quote}</Markup>
            </p>
            <div className="border" />
            <p className="source">
              <Markup>{quote2.source}</Markup>
            </p>
          </div>
        </div>
        <div className="circle circle-3">
          <div className="bg">
            <Markup>{redCircle}</Markup>
          </div>
          <div className="copy">
            <div className="quote-graphic">
              <Markup>{quoteGraphic}</Markup>
            </div>
            <p className="quote">
              <Markup>{quote3.quote}</Markup>
            </p>
            <div className="border" />
            <p className="source">
              <Markup>{quote3.source}</Markup>
            </p>
          </div>
        </div>
      </div>
      <div className="order-btn parallax-2">
        <Link to="/reviews">
          <PillButton className="white-red">{buttonText}</PillButton>
        </Link>
      </div>
    </Section>
  )
}

export default HomeSection4
