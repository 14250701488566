import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import Section from "src/components/section"
import { useHomeStore } from "src/state/home"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { redCircle } from "src/components/svgs"
import author from "src/images/author.png"

const HomeSection6 = () => {
  const {
    section6: { title, videoUrl, circleTitle, circleBody, circleButtonText, authorAlt },
  } = useHomeStore()

  const iframeRef = useRef()

  useEffect(() => {
    const iframe = iframeRef.current
    const resizeObserver = new ResizeObserver(() => {
      iframe.style.height = `${Math.floor(iframe.offsetWidth * (9 / 16))}px`
    })
    resizeObserver.observe(iframe)
    return () => resizeObserver.unobserve(iframe)
  })

  return (
    <Section className="home-section-6">
      <h2 className="title">{title}</h2>
      <div className="content">
        <div className="video parallax-2">
          <iframe
            ref={iframeRef}
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="circle">
          <div className="bg">
            <Markup>{redCircle}</Markup>
          </div>
          <div className="copy">
            <div className="top">
              <img className="author" src={author} alt={authorAlt} />
              <p className="circle-title">{circleTitle}</p>
            </div>
            <p className="circle-body">{circleBody}</p>
            <Link to="/author">
              <PillButton className="white-grey">{circleButtonText}</PillButton>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HomeSection6
