import React, { useState, useEffect } from "react"
import Section from "src/components/section"
import c from "classnames"
import { useHomeStore } from "src/state/home"
import { useMainStore } from "src/state/main"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { exampleCircle, accordionChevron } from "src/components/svgs"
import { useOrderMenuStore } from "src/state/order-menu"

const HomeSection3 = () => {
  const {
    section3: { title, subtitle, buttonText, buttonDescription, examples },
  } = useHomeStore()

  const { windowWidth } = useMainStore()
  const { setShouldShowOrderMenu } = useOrderMenuStore()

  const autoAdvanceDelay = 6000
  const [currentExample, setCurrentExample] = useState(examples[0])
  const [shouldAutoAdvance, setShouldAutoAdvance] = useState(true)

  useEffect(() => {
    const timer =
      shouldAutoAdvance &&
      setTimeout(() => {
        let nextIndex = examples.indexOf(currentExample) + 1
        if (nextIndex > examples.length - 1) nextIndex = 0
        windowWidth > 1150 && setCurrentExample(examples[nextIndex])
      }, autoAdvanceDelay)
    return () => clearTimeout(timer)
  }, [currentExample, shouldAutoAdvance, examples, windowWidth])

  return (
    <Section className="home-section-3">
      <div className="desktop">
        <div className="copy">
          <h2 className="title">{title}</h2>
          <h3 className="subtitle">
            <Markup>{subtitle}</Markup>
          </h3>
          <div className="example-nav">
            {examples.map((example) => (
              <div
                key={example.title}
                className={c("btn", example === currentExample && "selected")}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setShouldAutoAdvance(false)
                  setCurrentExample(example)
                }}
              >
                <p className="example-title">{example.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="examples parallax-3">
          {examples.map((example) => (
            <div
              key={example.title}
              className={c("example", example === currentExample && "selected")}
            >
              <div className="example-copy">
                <div className="example-circle">
                  <Markup>{exampleCircle}</Markup>
                </div>
                <p className="example-title">
                  <Markup>{example.title}</Markup>
                </p>
                <p className="example-body">
                  <Markup>{example.body}</Markup>
                </p>
                <div className="example-svg">
                  <div className="line" />
                  {example.svg ? (
                    <Markup>{example.svg}</Markup>
                  ) : (
                    <img src={example.png} alt={example.pngAlt} />
                  )}
                </div>
              </div>
              <div className="example-image">
                <img src={example.img} alt={example.alt} />
              </div>
            </div>
          ))}
        </div>
        <div className="btn-container">
          <div className="order-btn">
            <PillButton onClick={() => setShouldShowOrderMenu(true)}>{buttonText}</PillButton>
          </div>
          <p className="btn-description">{buttonDescription}</p>
        </div>
      </div>
      <div className="mobile">
        <h2 className="title">{title}</h2>
        <h3 className="subtitle">
          <Markup>{subtitle}</Markup>
        </h3>
        <div className="examples">
          {examples.map((example) => (
            <div
              key={example.title}
              className={c("example", example === currentExample && "selected")}
            >
              <div className="tab-border" />
              <div
                className="tab"
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setShouldAutoAdvance(false)
                  setCurrentExample(example)
                }}
              >
                <h3 className="tab-title">{example.title}</h3>
                <div className="chevron">
                  <Markup>{accordionChevron}</Markup>
                </div>
              </div>
              <div className="tab-content">
                <div className="top">
                  <p className="example-body">
                    <Markup>{example.body}</Markup>
                  </p>
                  <div className="example-image">
                    <img src={example.img} alt={example.alt} />
                  </div>
                </div>
                <div className="border" />
                <div className="bottom">
                  {example.svg ? (
                    <Markup>{example.svg}</Markup>
                  ) : (
                    <img src={example.png} alt={example.pngAlt} />
                  )}
                </div>
              </div>
              <div className="tab-border" />
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default HomeSection3
