import React from "react"
import { Link } from "gatsby"
import Section from "src/components/section"
import { useHomeStore } from "src/state/home"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { redCircle, blueCircle } from "src/components/svgs"

const HomeSection5 = () => {
  const {
    section5: { title, subtitle, circle1Title, circle1ButtonText, circle2Title, circle2ButtonText },
  } = useHomeStore()

  return (
    <Section className="home-section-5">
      <h2 className="title parallax-1">{title}</h2>
      <h3 className="subtitle h3 parallax-2">{subtitle}</h3>
      <div className="circles parallax-3">
        <div className="circle circle-1">
          <div className="bg">
            <Markup>{redCircle}</Markup>
          </div>
          <div className="copy">
            <p className="text">{circle1Title}</p>
            <Link to="/training">
              <PillButton className="white-grey">{circle1ButtonText}</PillButton>
            </Link>
          </div>
        </div>
        <div className="circle circle-2">
          <div className="bg">
            <Markup>{blueCircle}</Markup>
          </div>
          <div className="copy">
            <p className="text">{circle2Title}</p>
            <Link to="/resources">
              <PillButton className="white-red">{circle2ButtonText}</PillButton>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HomeSection5
