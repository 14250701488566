import React from "react"
import Page from "src/components/page"
import { useHomeStore } from "src/state/home"
import HomeSection1 from "src/components/home-section-1"
import HomeSection2 from "src/components/home-section-2"
import HomeSection3 from "src/components/home-section-3"
import HomeSection4 from "src/components/home-section-4"
import HomeSection5 from "src/components/home-section-5"
import HomeSection6 from "src/components/home-section-6"

const IndexPage = () => {
  const { meta } = useHomeStore()
  return (
    <Page {...meta} totalSections={6}>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
    </Page>
  )
}

export default IndexPage
