import React from "react"
import Section from "src/components/section"
import { useHomeStore } from "src/state/home"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import book from "src/images/book.png"
import { blueBgCircle, redCircle, quoteGraphic } from "src/components/svgs"
import { useOrderMenuStore } from "src/state/order-menu"

const HomeSection1 = () => {
  const {
    section1: { title, body, subtitle, buttonText, quoteText, quoteSource, bookAlt },
  } = useHomeStore()
  const { setShouldShowOrderMenu } = useOrderMenuStore()

  const quoteNode = (
    <div className="quote">
      <div className="quote-bg">
        <Markup>{redCircle}</Markup>
      </div>
      <div className="text-container">
        <div className="quote-graphic">
          <Markup>{quoteGraphic}</Markup>
        </div>
        <div className="quote-text-container">
          <div className="quote-text">{quoteText}</div>
          <div className="quote-source">{quoteSource}</div>
        </div>
      </div>
    </div>
  )

  const bookNode = (
    <div className="book">
      <img src={book} alt={bookAlt} />
    </div>
  )

  const bgCircleNode = (
    <div className="bg-circle parallax-n1">
      <Markup>{blueBgCircle}</Markup>
    </div>
  )

  return (
    <Section className="home-section-1">
      {bgCircleNode}
      <div className="copy parallax-1">
        <div className="book-node-mobile">{bookNode}</div>
        <h2 className="title">{title}</h2>
        <p className="body">{body}</p>
        <h3 className="subtitle">{subtitle}</h3>
        <PillButton onClick={() => setShouldShowOrderMenu(true)} className="order-btn parallax-n1">
          {buttonText}
        </PillButton>
        <div className="quote-node-mobile">{quoteNode}</div>
      </div>
      <div className="graphics parallax-2">
        {quoteNode}
        {bookNode}
      </div>
    </Section>
  )
}

export default HomeSection1
